var $ = require ('jquery');

var html = require ('./footer.html');
require ('./footer.css');


function CreateFooter (element)
{
	var stash = pageStash;

	$(element).append (html);

	var catalyst = stash.catalyst || {};
	var data =
	{
		resolutionText: stash.text.Screen_Resolution,
		developedForText: stash.text.Developer_For_Screen_Size,
		copyrightText: stash.text.Copyright.replace ('__CURRENT_YEAR__', new Date().getFullYear()),
		versionText: stash.text.Version_Text,
		fastCgiWarningText: stash.text.FastCgiWarning,
		buildDateText: stash.text.Build_Date,

		productVersion: stash.installation.Product_Version,
		screen: screen,

		noFastCgi: catalyst.notFastCgiOptimised,

		internal: stash.installation.isInternal,
		buildDate: stash.installation.buildDate,
		warningImage: require ('./warning.png').default,
	};

	var rivets = require ('striata-rivets');
	rivets.bind ($('#striataFooter'), data);
}


module.exports.CreateFooter = CreateFooter;

