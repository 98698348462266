function _IsDefined(value)
{
	if (typeof (value) !== 'undefined')
	{
		return true;
	}

	return false;
}



function _DefaultInit(value, defaultValue)
{
	if (_IsDefined (value))
	{
		return value;
	}

	return defaultValue;
}



function _RequiredInStruct(requiredFields, struct, context)
{
	for (var i = 0; i < requiredFields.length; i++)
	{
		var fieldName = requiredFields[i];
		if (! _IsDefined(struct[fieldName]))
		{
			throw new Error ("Programmer error: '" + fieldName + "' is a required field in " + context);
		}
	}
}



function _GetUUID()
{
	//RFC-Compliant pseudo UUID
	//This is probably only safe for temporary use (non-persisted Id's)
	//This could be somewhat improved by adding a time component

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace (/[xy]/g, function (c)
		{
			var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
			return v.toString (16);
		}
	);
}



function HighestZIndex()
{
	var zIndex;
	var maxIndex = 0;
	var documentElements = document.getElementsByTagName ('*');
	for (var index = 0; index < documentElements.length; ++index)
	{
		zIndex = document.defaultView.getComputedStyle (documentElements[index], null).getPropertyValue ("z-index");
		zIndex = parseInt (zIndex, 10);
		maxIndex = (zIndex) ? Math.max (maxIndex, zIndex) : maxIndex;
	}

	return maxIndex;
}


// Export
if (typeof module !== 'undefined' && module.exports)
{
	module.exports._IsDefined = _IsDefined;
	module.exports._DefaultInit = _DefaultInit;
	module.exports._GetUUID = _GetUUID;
	module.exports._RequiredInStruct = _RequiredInStruct;
	module.exports.HighestZIndex = HighestZIndex;
}

