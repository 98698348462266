
// Import
var assert = require ('assert');
var jQuery = require ('striata-jquery');
var _ = require ('lodash');
require ('./inlinenotification.css');


(function($)
{
	var supportedTypes =
	{
		"loading":
		{
			image: require ('./spinner.gif').default,
			name: 'loadingImage',
		},
		"success":
		{
			image: require ('./success.png').default,
			name: 'successImage',
		},
		"warning":
		{
			image: require ('./warning.png').default,
			name: 'warnImage',
		},
		"error":
		{
			image: require ('./error.png').default,
			name: 'errorImage',
		},
	};

	function CreateImage (type)
	{
		var img = $('<img />');
		img.addClass ('inlineNotificationImage');
		img.attr ('src', supportedTypes[type].image);
		img.attr ('name', supportedTypes[type].name);
		return img;
	}

	var methods =
	{
		// Display the inline notification styled by the status of the action.
		show : function (options)
		{
			var obj = $(this);

			assert (obj.attr ('id'), "IN: Element has no id");
			assert (options.status, "No status specified");
			assert (options.status in supportedTypes, "Unsupported status");

			// Make sure the notification image div is vertically centered to the inline's parent div.
			var topMargin = obj.parent().height() ? Math.round ((obj.parent().height() - 16) / 2) : 4;

			obj.css ("line-height", obj.height() + "px");
			obj.css ("margin-top", topMargin + "px");
			obj.addClass ("inlineNotification");

			// Remove any previously set classes
			Object.keys (supportedTypes).forEach (function (type)
				{
					obj.removeClass ("inlineNotification" + _.capitalize (type));
				}
			);

			obj.addClass ("inlineNotification" + _.capitalize (options.status));

			obj.empty();
			obj.append (CreateImage (options.status));

			if (options.message)
			{
				obj.children ('img').last().on ('click', function (event)
					{
						var popupClass = obj.attr ('id') + "formError";

						if ($("." + popupClass).length)
						{
							// Already showing, toggle
							$("." + popupClass).remove();
							return;
						}

						obj.validationEngine ('showPrompt', options.message, 'load', 'topLeft', true);

						var modal = $("." + popupClass);
						modal.addClass ("inlineNotificationModal");
						modal.addClass ("inlineNotificationModal" + _.capitalize (options.status));

						modal.offset (
						{
							top  : obj.offset().top - modal.height() - 4,
							left : obj.offset().left - 12
						});
					}
				);
			}

			obj.show();
		},

		// Hide the inline notification either immediately or after a predetermined time.
		hide : function (options)
		{
			var obj = $(this);

			// TODO!
			if (!obj.attr ('id'))
			{
				return;
			}

			options = options || {};

			function HideInlineNotification (duration)
			{
				obj.fadeOut (duration);
				obj.validationEngine ('hide');
			}

			if (options.delayTime)
			{
				setTimeout (HideInlineNotification.bind (null, 'fast'),
					options.delayTime);
			}
			else
			{
				HideInlineNotification (0);
			}
		}
	};

	$.fn.inlineNotification = function (method)
	{
		assert (method);
		assert (method in methods);

		return methods[method].apply (this, Array.prototype.slice.call (arguments, 1));
	};

})(jQuery);
