
// Import
var jQuery = require ('striata-jquery');
var $ = jQuery;
var _IsDefined = require ('@utility/utility')._IsDefined;
var _DefaultInit = require ('@utility/utility')._DefaultInit;
var _GetUUID = require ('@utility/utility')._GetUUID;
var StriataError = require ('@control/error/striataerror').StriataError;
var StriataErrorMessage = require ('@control/error/striataerror').StriataErrorMessage;


function AsyncFormPostRequestDispatchTypeTransport (settings)
{
	//ie has a bug that it ignores the name you set on dynamically created dom elements
	function CreateNamedElement (element, name)
	{
		var myNewElement = jQuery('<' + element + ' name ="' + name + '"></'+ element +'>');
		return myNewElement[0];
	}

	this.fields = [];
	this.onSuccess = function () {};
	this.onError = function () {};
	this.GetFormId = function ()
	{
		return this.formElement.id;
	};


	this.UpdateSettings = function (config)
	{
		for (var key in config)
		{
			if (Object.prototype.hasOwnProperty.call (config, key))
			{
				switch (key)
				{
					case "formId"  :    this.SetFormId(config[key]); break;
					case "url"     :    this.SetUrl(config[key]); break;
					case "success" :    this.onSuccess = config[key]; break;
					case "error"   :    this.onError = config[key]; break;
					case "context" :    this.context = config[key]; break;
					case "data"    :    this.SetData(config[key]); break;
					default:
				}
			}
		}
	};

	this.KillCurrentlyPostingIframe = function()
	{
		if (this.iframeElement.contentWindow.stop)
		{
			this.iframeElement.contentWindow.stop(); //for anything but IE
		}
		else
		{
			this.iframeElement.contentWindow.document.execCommand ("Stop"); // for IE
		}
	};


	this.SetUrl = function(value)
	{
		if (_IsDefined(this.formElement))
		{
			this.formElement.action = value;
		}
		this.url = value;
	};

	this.MakeForm = function (formIdArgument)
	{
		var formId = _DefaultInit (formIdArgument, _GetUUID());
		var formElement = CreateNamedElement('form', formId);
		formElement.id = formId;
		formElement.method = "POST";
		formElement.enctype="multipart/form-data";
		formElement.action = this.url;
		this.formElement = formElement;
		this.formId = formId;
		this.formPublished = false;
	};

	this.SetFormId = function (formId)
	{
		this.PrepareFormElement(formId);
	};

	this.PrepareFormElement = function (formId)
	{
		if (_IsDefined(formId))
		{
			this.formId = formId;
			var formElement = document.getElementById(formId);
			if (formElement === null)
			{
				this.MakeForm(formId);
			}
			else
			{
				this.formElement = formElement;
				this.formPublished = true;
			}
		}
		else
		{
			this.MakeForm();
		}
		this.formElement.action = this.url;
	};

	function InputField (name, value, type, id)
	{
		var self = this;
		this.id    = _DefaultInit (id, _GetUUID());
		this.name  = _DefaultInit (name, this.id);
		this.type  = _DefaultInit (type, 'hidden');
		this.value = _DefaultInit (value, '');

		var myNewInputElement = jQuery('<input name ="' + this.name + '" type ="' + this.type + '"></input>');
		this.element = myNewInputElement[0];
		this.element.id = this.id;
		this.element.value = this.value;
		this.autoAdded = false;

		this.GetElement = function () { return self.element    };
		this.GetId      = function () { return self.id    };
		this.GetValue   = function () { return self.Value };
		this.GetName    = function () { return self.name  };
		this.GetType    = function () { return self.type  };

		this.SetAutoAdded = function (value) { self.autoAdded = value };
		this.IsAutoAdded   = function ()  { return self.autoAdded };

		this.SetValue = function (value)
		{
			this.element.value = value;
			this.value = value;
		};
	}

	this.MakeIframe = function ()
	{
		this.iframeName = _GetUUID();
		var iframe = CreateNamedElement ('iframe', this.iframeName);
		iframe.id = this.iframeName;
		this.iframeElement = iframe;
	};

	this.AddField = function (isAutoAdded, name, value, type, id)
	{
		var input = new InputField (name, value, type, id);
		if (isAutoAdded === true)
		{
			if (name == 'showType')
			{
				return;
			}
			var elements = this.formElement.elements;
			for (var i = 0; i < elements.length; i++)
			{
				if (elements[i].name == name)
				{
					if ((!type) || (type == 'hidden'))
					{
						return;
					}
				}
			}
			input.SetAutoAdded(true);
		}

		this.fields.push(input);
		if (! _IsDefined(this.formElement))
		{
			this.MakeForm();
		}

		this.CreateAutoFieldDiv();

		this.autoFieldDiv.appendChild(input.element);
		return input;
	};

	this.CreateAutoFieldDiv = function ()
	{
		//create div to keep custom fields
		if (! $(this.formElement).find("#HiddenAutoFields").length)
		{
			var div = CreateNamedElement('div', "HiddenAutoFields");
			div.id = "HiddenAutoFields";
			this.formElement.insertBefore(div, this.formElement.firstChild);
			this.autoFieldDiv = div;
		}
		else
		{
			if (typeof this.autoFieldDiv == "undefined")
			{
				this.autoFieldDiv = $(this.formElement).find("#HiddenAutoFields").get();
			}
		}
	};

	this.DestroyAutoFieldDiv = function ()
	{
		$(this.formElement).find("#HiddenAutoFields").remove();
	};

	this.AddCustomField = function (name, value, type, id)
	{
		return this.AddField (false, name, value, type, id);
	};

	this.AddAutoField = function (name, value, type, id)
	{
		return this.AddField (true, name, value, type, id);
	};

	this.ParseData = function (dataArgument)
	{
		var data = _DefaultInit (dataArgument, this.data);
		for (var key in data)
		{
			this.AddAutoField(key, data[key]);
		}
	};

	this.SetData = function (dataArgument)
	{
		this.ClearAutoFields();
		this.ParseData(dataArgument);
	};

	//Register onload function where the iframe child document can get to it
	// It has to be global unfortunately.
	this.RegisterOnload = function ()
	{
		if (! _IsDefined (document.uploadIframeOnload))
		{
			document.uploadIframeOnload = {};
		}
		document.uploadIframeOnload[this.iframeName] = this.IframeOnload;
	};

	//Add form to DOM
	this.PublishForm = function()
	{
		if (this.formPublished !== true)
		{
			var div = document.createElement('div');
			div.style.display = "none";
			div.appendChild(this.formElement);
			document.body.appendChild (div);
			this.formPublished = true;
		}
	};
	this.PublishIframe = function()
	{
		if (this.iframePublished !== true)
		{
			var div = document.createElement('div');
			div.style.display = "none";
			div.appendChild (this.iframeElement);
			document.body.appendChild (div);
			this.RegisterOnload();
			this.iframePublished = true;
		}
	};


	//Get ready to post again
	this.Reset = function ()
	{
		this.ClearAutoFields();
	};

	this.ClearAutoFields = function()
	{
		var newFields = [];
		for (var i = 0; i < this.fields.length ; i++)
		{
			var field = this.fields[i];
			if (field.IsAutoAdded() === true)
			{
				field.element.parentNode.removeChild(field.element);
			}
			else
			{
				newFields.push(field);
			}
		}
		this.fields = newFields;

		this.DestroyAutoFieldDiv();
		this.CreateAutoFieldDiv();

	};

	this.GetIframeContent = function (iframeId)
	{
		var iFrame =  document.getElementById(iframeId);
		var iFrameBody;
		if ( iFrame.contentDocument )
		{ // FF
			iFrameBody = iFrame.contentDocument.getElementsByTagName('body')[0];
		}
		else if ( iFrame.contentWindow )
		{ // IE
			iFrameBody = iFrame.contentWindow.document.getElementsByTagName('body')[0];
		}

		return iFrameBody.innerHTML;
	};

	this.HandleCallBackFromIframe = function (iframeId)
	{
		var error;
		try
		{
			document.getElementById (iframeId).contentDocument.location.href;
		}
		catch (e)
		{
			error = new StriataErrorMessage ("1", "500", window.httpError, window.httpError, "");
			return this.onError (error);
		}

		if (typeof(document.getElementById(iframeId).contentWindow.PublishUploadResponse) == 'function')
		{
			//alert('here we had error we can handle in iframe');
		}
		else
		{
			var iframeContent = this.GetIframeContent (iframeId);
			error = new StriataError();
			error.AddErrorMessage(1, 0, iframeContent);
			return this.onError (error);
		}
	};


	var self = this;
	this.Send = function(config)
	{
		this.Reset();
		this.UpdateSettings(config);
		this.formElement.target=this.iframeName;
		this.PublishIframe();

		if (this.iframeElement.attachEvent)
		{
			this.iframeElement.attachEvent("onload", function()
			{
				self.HandleCallBackFromIframe (this.id);
			});
		}
		else
		{
			this.iframeElement.onload = function()
			{
				self.HandleCallBackFromIframe (this.id);
			};
		}

		this.formElement.submit();
	};

	this.IframeOnload = function (eventData)
	{
		var error;
		const UPLOAD_ERROR = 0;
		const UPLOAD_SUCCESS = 1;

		if (typeof (eventData) == "string" || eventData == UPLOAD_ERROR)
		{
			try
			{
				eventData = JSON.parse (eventData);
			}
			catch (err)
			{
				error = new StriataError();
				error.AddErrorMessage(1, -3220, "Invalid response from server", "Invalid Response(could not parse json)");
				return self.onError (err);
			}

		}

		if (typeof (eventData) == "object" || eventData == UPLOAD_SUCCESS)
		{
			if (eventData !== null )
			{
				return self.onSuccess (eventData);
			}
		}

		error = new StriataError();
		error.AddErrorMessage(1, -3220, "Invalid response from server", "Invalid Response(");
		return self.onError (error);
	};

	this.Initialise = function (config)
	{
		this.UpdateSettings(config);

		this.MakeIframe();
		if (! _IsDefined(this.formElement))
		{
			this.PrepareFormElement();
		}

		// this should be true for all fom submission
		this.formElement.method = "POST";

		this.PublishForm();
	};
}

// Export
module.exports = AsyncFormPostRequestDispatchTypeTransport;

