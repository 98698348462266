var glossary_help = new Object();
glossary_help['projects']='A project contains, and allows users to manage, all components required to define and send related communications. ';
glossary_help['project']='A project contains, and allows users to manage, all components required to define and send related communications. ';
glossary_help['theme']='Themes are precreated message layouts with placeholder text and images that can be edited.';
glossary_help['themes']='Themes are precreated message layouts with placeholder text and images that can be edited.';
glossary_help['test_communication']='A test communication is used as a quality check before the live communication is sent.';
glossary_help['live_communication']='A live communication (the intended recipients will receive the messages) without a final check being performed.';
glossary_help['communications']='A communication houses and manages related messages as well as the process of sending these messages to the end recipient.';
glossary_help['communication']='A communication houses and manages related messages as well as the process of sending these messages to the end recipient.';
glossary_help['data_file']='A data file is the orginal file that contains the actual recipient data.';
glossary_help['data_source']='A datasource is a specialised script designed to parse a data file of a specific format. Each data file format (e.g. CSV) will therefore require a correlating data source (i.e. A CSV data source) before it can be used to create and send communications.';
glossary_help['data_set']='A data set contains the uploaded data file as well as a reference to the relevant datasource that will be used to parse the data file. The data set will be used to present the original recipient data for use in a communication.';
glossary_help['data_sets']='A data set contains the uploaded data file as well as a reference to the relevant datasource that will be used to parse the data file. The data set will be used to present the original recipient data for use in a communication.';
glossary_help['templates']='A template provides the type, content and settings of a message to be used within a communication. A template can be used multiple times, across multiple communications.';
glossary_help['template']='A template provides the type, content and settings of a message to be used within a communication. A template can be used multiple times, across multiple communications.';
glossary_help['element']='An element is a component of a template, when combined with other elements defines the content and attachments of that message. Examples of elements include images, html files, text files, PDFs, EMCs etc.';
glossary_help['elements']='An element is a component of a template, when combined with other elements defines the content and attachments of that message. Examples of elements include images, html files, text files, PDFs, EMCs etc.';
glossary_help['branch_view']='The ability to view the contents of the current zone and it\'s subzones within the same view.';
glossary_help['zone_tree']='A zone tree is a platform wide tool that allows users to navigate through the zones that are available within their permission scope.';
glossary_help['zone']='A zone allows users to categorise projects, queues and communications within the zone tree.';
glossary_help['legacy_projects']='Projects created in a previous version of the Striata application platform.';
glossary_help['home_zone']='A home zone is allocated to a user in order to make the user available to recieve permissions in that zone and it\'s subzones. A user may have one or many home zones.';
glossary_help['substitution']='The ability to include placeholders in the content of the message that get substituted by values in the data. Substituition is most commonly used to personalise messages.';
glossary_help['substituted']='The ability to include placeholders in the content of the message that get substituted by values in the data. Substituition is most commonly used to personalise messages.';
glossary_help['message_range']='A notation for the selecting a specific range of messages. The notation allows for the use of a "-" for defining a "between" range (e.g. "1-10" is the equaivalent of saying select all messsage from 1 to 10). The notation also allows for commas to be used to define a specific range (e.g. "1,3,5,7" will specifically select message 1, 3, 5 and 7).';
glossary_help['build_log']='A report detailing the step by step build process including successes and failures.';
glossary_help['message_body']='The message body is defined by the HTML and text elements.';
glossary_help['base_url']='A base URL is the complete URL of the server hosting the HTML files.';
glossary_help['unsubstituted']='Displays content with the substitution codes rather than the substituted values.';


if (typeof module !== 'undefined' && module.exports)
{
	module.exports = glossary_help;
}

