// Import
if (typeof module !== 'undefined' && module.exports)
{
	// This module is shared by the Medusa front-end, and thus needs to be guarded
	var $ = require ('striata-jquery');
}


function ShowSystemError (id, messageText, preFormatted)
{
	if (id.charAt(0) == '#')
	{
		id = id.substr(1);
	}
	//alert (messageText);
	if ( typeof (preFormatted) == 'undefined')
	{
		preFormatted = false;
	}

	if ( preFormatted == true)
	{
		messageText = '<pre>' + messageText + '</pre>';
	}

	var correspongingDivId = $('#' + id + 'ErrorModal').attr ('correspongingDivId');

	$('#' + id + 'ErrorModal').css ('width', $('#' + correspongingDivId).css ('width'));
	$('#' + id + 'ErrorModal').css ('height', $('#' + correspongingDivId).css ('height'));
	$('#' + id + 'ErrorContent').html (messageText);

	$('#' + correspongingDivId).hide();
	$('#' + id + 'ErrorModal').show();
}



function HideSystemErrors (modalId)
{
	function _HideSystemErrorModalElement (elementId)
	{
		var correspongingDivId = $('#' + elementId).attr ('correspongingDivId');

		//here we need to get the parent of the id as it might be in a wizard step

		if (typeof correspongingDivId != 'undefined' && $('#' + correspongingDivId).hasClass('wizardContainerBorder').toString() == 'false')
		{
			$('#' + correspongingDivId).show();
			$('#' + elementId).hide();
		}
	}

	if (modalId.charAt(0) == '#')
	{
		modalId = modalId.substr(1);
	}

	_HideSystemErrorModalElement (modalId+'ErrorModal');

	$.each($('#' + modalId).parent().find('.systemErrorSection'), function (index, element)
		{
			_HideSystemErrorModalElement (element.id);
		}
	);
}


// Export
if (typeof module !== 'undefined' && module.exports)
{
	module.exports.ShowSystemError = ShowSystemError;
	module.exports.HideSystemErrors = HideSystemErrors;
}

