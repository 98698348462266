var $ = require ('striata-jquery');
var rivets = require ('rivets');


rivets.binders['addclass-*'] = function (el, value)
{
	var id = this.type;

	if (el.addedClass && el.addedClass[id])
	{
		$(el).removeClass (el.addedClass[id]);
	}

	if (value)
	{
		$(el).addClass (value);

		el.addedClass = el.addedClass || {};
		el.addedClass[id] = value;
	}
};

rivets.formatters.add = function (target, value)
{
	return target + value;
};

rivets.formatters.eq = function (target, value)
{
	return target === value;
};

rivets.formatters.ne = function (target, value)
{
	return target !== value;
};

rivets.formatters.and = function (target, value)
{
	return target && value;
};

rivets.formatters.or = function (target, value)
{
	return target || value;
};

rivets.formatters.not = function (target)
{
	return !target;
};

rivets.formatters.if = function (target, ifTrue, ifFalse)
{
	return target ? ifTrue : ifFalse;
};


module.exports = rivets;

