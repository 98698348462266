
// Import
var jQuery = require ('striata-jquery');
var $ = jQuery;

// TODO: This adds .Throbber()
require ('@control/spinner/jquery.spinner.js');


function BuildPostForm (url, data, target)
{
	if (url == "")
	{
		return false;
	}

	$(window).off ("beforeunload");
	$(window).off ("ajaxStart");
	$(window).off ("ajaxStop");
	//$(this).ThrobberHide();

	if (target != "_blank")
	{
		$(this).Throbber();
	}

	//remove any previous version of the form if the user hit the backbutton
	jQuery('#formPost').remove();

	var myform = jQuery("<form></form>");
	myform.attr("id","formPost");
	myform.attr("name","formPost");
	myform.attr("action",url);
	myform.attr("method","POST");

	if (target)
	{
		myform.attr("target", target);
	}

	//Add platform standard fields
	var defaultFormValues =
	{
		'sessionId' : window.sessionId,
		'selectedZoneId' : window.selectedZoneId,
		'csrf' : window.csrf,
		'pageId' : window.pageId,
		'userName': window.userName,
		'currentProjectId' : window.currentProjectId
	};

	jQuery.extend(defaultFormValues, data);

	for (var i in defaultFormValues)
	{
		var input = jQuery("<input>");
		input.attr("type", "hidden");
		input.attr("name", i);
		input.attr("id", i+'_formPost');
		input.attr("value", defaultFormValues[i]);
		myform.append(input);
	}

	myform.appendTo(document.body);
	jQuery('#formPost').trigger ('submit');
}


$(function()
{
	$(window).on ("beforeunload", function()
	{
		//here call the formcompare function if form fails return str and then focus on form
		CheckIfSpinnerIsDisplayed('display');
	});

	$('body').ajaxStart (function()
	{
		CheckIfSpinnerIsDisplayed('display');
	});

	$('body').ajaxStop (function()
	{
		CheckIfSpinnerIsDisplayed('hide');
	});
});

function CheckIfSpinnerIsDisplayed (type)
{
	//this is used to check if any spinner and if not draw over the page
	var isVisible = $('.throbber').is (':visible');
	var isLoading = $('.throbberLoading').is (':visible');

	//override for zone tree
	var jsTreeLoading = $('.jstree-loading').is (':visible');
	if (jsTreeLoading == true)
	{
		return true;
	}

	if ( (isVisible == false && isLoading == false) && type == 'display')
	{
		$(this).Throbber();
	}

	if (isVisible == true && type == 'hide')
	{
		$(this).ThrobberHide();
	}
}


// Export
module.exports = BuildPostForm;

