// Import
var $ = require ('striata-jquery');
var ShowSystemError = require ('./systemerror').ShowSystemError;


function StriataError()
{
	this.numApplicationErrors = 0;
	this.numSystemErrors = 0;
	this.numErrors = 0;
	this.errorWho = '';
	this.errorWhere = '';
	this.errorWhen = '';
	this.errorUserAgent = '';
	this.errorServer = '';
	this.errorHTTPReferrer = '';
	this.errorMessages = [];
}



function StriataErrorMessage (level, code, description, narrative, customParameters)
{
	var errorService = "";

	if (customParameters && customParameters.service)
	{
		errorService = " (" + customParameters.service + ")";
	}

	this.errorLevel = level;
	this.errorCode = code;
	this.errorNarrative = narrative + errorService;
	this.errorDescription = description + errorService;
	this.customParameters = customParameters;

	this.AsString = function()
	{
		var result = 'Unknown error';
		if ((this.errorNarrative) && (this.errorNarrative.length))
		{
			result = this.errorNarrative;
		}
		else if ((this.errorDescription) && (this.errorDescription.length))
		{
			result = this.errorDescription;
		}

		if ((this.errorCode) && (parseInt (this.errorCode, 10) != 0))
		{
			result = result + ' (' + parseInt (this.errorCode, 10) + ')';
		}

		return result;
	};

	TriggerCustomErrorHandler (code);
}



function AddCustomErrorHandler (errorCode, errorCallBack)
{
	if (!$("body").data ("errorHandlers"))
	{
		$("body").data ("errorHandlers", []);
	}

	for (var handleIndex in $("body").data ("errorHandlers"))
	{
		if ($("body").data ("errorHandlers")[handleIndex]["code"] == errorCode)
		{
			return;
		}
	}

	var errorHandleObject = {
		"code"     : errorCode,
		"callBack" : errorCallBack
	};

	$("body").data ("errorHandlers").push (errorHandleObject);
}



function TriggerCustomErrorHandler (errorCode)
{
	for (var errorHandle in $("body").data ("errorHandlers"))
	{

		if ($("body").data ("errorHandlers")[errorHandle].code == errorCode)
		{
			$("body").data ("errorHandlers")[errorHandle].callBack();
			break;
		}
	}
}



StriataError.prototype.AddErrorMessage = function (level, code, description, narrative, customParameters)
{
	var error = new StriataErrorMessage (level, code, description, narrative, customParameters);

	if (level == 1)
	{
		this.numApplicationErrors += 1;
		this.numErrors += 1;
	}

	if (level == 2)
	{
		this.numSystemErrors += 1;
		this.numErrors += 1;
	}

	this.errorMessages.push (error);
};



StriataError.prototype.PopulateFromJson = function (jsonData)
{
	if (jsonData.numErrors !== undefined)
	{
		this.numApplicationErrors = jsonData.numApplicationErrors;
		this.numSystemErrors = jsonData.numSystemErrors;
		this.numErrors = jsonData.numErrors;
		this.errorWho = jsonData.errorWho;
		this.errorWhere = jsonData.errorWhere;
		this.errorWhen = jsonData.errorWhen;
		this.errorUserAgent = jsonData.errorUserAgent;
		this.errorServer = jsonData.errorServer;
		this.errorHTTPReferrer = jsonData.errorHTTPReferrer;
		this.errorMessages = [];

		for (var i = 0; i < this.numErrors; i++)
		{
			jsonData.errorMessages[i].errorNarrative = jsonData.errorMessages[i].errorNarrative || jsonData.errorMessages[i].errorDescription;

			var error = new StriataErrorMessage (
				jsonData.errorMessages[i].errorLevel,
				jsonData.errorMessages[i].errorCode,
				jsonData.errorMessages[i].errorDescription,
				jsonData.errorMessages[i].errorNarrative,
				jsonData.errorMessages[i].errorParameter
			);

			this.errorMessages.push (error);
		}
	}
};



StriataError.prototype.ToHtml = function()
{
	var errorHtml = '';

	if (this.numErrors > 0)
	{
		for (var i = 0; i < this.numErrors; ++i)
		{
			var errorMessage = this.errorMessages[i].errorDescription || this.errorMessages[i].errorNarrative;
			var errorString = '(' + this.errorMessages[i].errorCode + ') ' + errorMessage;

			if (this.numErrors > 1)
			{
				errorHtml = errorHtml + '<li style="margin-left: -30px">' + errorString + '</li>';
			}
			else
			{
				errorHtml = errorString;
			}
		}

		if (this.numErrors > 1)
		{
			errorHtml = "<ul>" + errorHtml + "</ul>";
		}
	}

	return errorHtml;
};



StriataError.prototype.ToString = function()
{
	var errorText = '';

	if (this.numErrors  > 0)
	{
		for (var i = 0; i < this.numErrors; ++i)
		{
			var currentError = this.errorMessages[i];

			if (currentError.errorHandled)
			{
				continue;
			}

			var errorString;
			var errorMessage = currentError.errorDescription || currentError.errorNarrative;

			errorString = '(' + currentError.errorCode + ') ' + errorMessage;
			errorText = errorText + errorString + '\n';
		}
	}

	return errorText;
};



StriataError.prototype.DisplayErrorMessageOnElement = function (elementId, displayFromLeft, showWarning)
{
	if (this.numErrors > 0)
	{
		var errorString = this.ToHtml();

		if (errorString && document.getElementById (elementId).parentElement.querySelectorAll (".formErrorContent").length == 0)
		{
			$("#" + elementId).validationEngine ("showPrompt", errorString, "error", "topRight", true);
			$("." + elementId + "formError").find (".formErrorContent").css ('width', 'auto');

			if (!displayFromLeft)
			{
				var documentWidth = $(window).width();
				var popupElement = elementId + "formError";

				$("." + popupElement).on ('click', function (event)
				{
					event.preventDefault();
					return false;
				});

				$("." + popupElement).on ('mouseover', function (event)
				{
					event.preventDefault();
					return false;
				});

				var popupElementOverlap = ($("." + popupElement).offset().left + parseInt ($("." + popupElement).outerWidth()) - documentWidth + 10);

				if (popupElementOverlap > 0)
				{
					var popupElementArrow = parseInt ($("." + popupElement).find (".formErrorArrow").css ("margin-left"), 10);

					$("." + popupElement).offset ({left: ($("." + popupElement).offset().left - popupElementOverlap)});
					$("." + popupElement).find (".formErrorArrow").css ('margin-left', popupElementArrow + popupElementOverlap + "px");
				}
			}

			if (showWarning)
			{
				$("." + elementId + "formError").addClass ("inlineNotificationModalWarning");
			}

			$("." + popupElement).on ('click', function()
			{
				$("#" + elementId).validationEngine ("hide");
			});
		}
	}
};



StriataError.prototype.DisplayInlineNotificationError = function (elementId, errors)
{
	errors = this.errorMessages || errors;
	if (this.numErrors > 0)
	{
		if (errors && errors.length)
		{
			for (var i = 0; i < this.numErrors; ++i)
			{
				for (var j = 0; j < errors.length; ++j)
				{
					var errorText = errors[j].text;

					if (!errorText || errorText == '')
					{
						errorText = "(" + errors.errorCode + ")" + errors.errorDescription;
					}

					this.errorMessages[i].errorHandled = true;
					$("#" + errors[j].elementId).validationEngine ("showPrompt", errorText, "error", "topRight", true);
				}
			}
		}

		var errorString = this.ToHtml();

		if (errorString != '')
		{
			$("#" + elementId).inlineNotification ("show",
			{
				"status"  : "error",
				"message" : errorString
			});
		}

		return true;
	}

	return false;
};



StriataError.prototype.DisplaySystemErrorMessage = function (id)
{
	if (this.numErrors  > 0)
	{
		var errorString = this.ToHtml();
		if (errorString != '')
		{
			ShowSystemError (id, errorString);
		}
	}
};


// Export
module.exports.StriataError = StriataError;
module.exports.StriataErrorMessage = StriataErrorMessage;
module.exports.AddCustomErrorHandler = AddCustomErrorHandler;

